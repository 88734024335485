.section-container {
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  background: #2d1b36; /* colors.darkestPurple */
  color: white;
  margin: 0;
}

.goals-container {
  border: 6px solid #bc7fd7; /* colors.lavender */
  box-shadow: 0 0 15px #bc7fd7, inset 0 0 15px #bc7fd7;
  height: 100%;
  position: relative;
}

.objectives-container {
  border: 6px solid #00ffff; /* colors.electricBlue */
  box-shadow: 0 0 15px #00ffff, inset 0 0 15px #00ffff;
  height: 100%;
  position: relative;  /* Add this line */
}

.tasks-container {
  border: 6px solid #ff69b4; /* colors.hotPink */
  box-shadow: 0 0 15px #ff69b4, inset 0 0 15px #ff69b4;
  height: 100%;
  position: relative;  /* Add this line */
}

.dashboard-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #191622; /* colors.cinder */
  box-sizing: border-box;
  padding: 4px;
  margin: 0;
  gap: 4px;
  overflow: hidden;
}

.goals-section {
  flex: 0 0 30%;
  width: 100%;
  min-height: 30%;
}

.lower-section {
  display: flex;
  flex: 0 0 70%;
  min-height: 70%;
  gap: 4px;
}

.objectives-section, .tasks-section {
  flex: 1;
  height: 100%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .lower-section {
      flex-direction: column;
      height: auto;
  }

  .objectives-section, .tasks-section {
      height: auto;
      min-height: 300px;
  }

  .goals-section {
      height: auto;
      min-height: 200px;
  }
}

/* Ensure proper box sizing throughout app */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.squares-container {
  height: 100%;
  overflow-y: auto;
  padding: 8px;
  padding-bottom: 48px; /* Make room for add button */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.squares-container::-webkit-scrollbar {
  display: none;
}

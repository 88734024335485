.square {
    width: 100%;
    margin-bottom: 4px;
    background: rgba(45, 27, 54, 0.8);
    border: 2px solid var(--glow-color);
    box-shadow: 0 0 10px var(--glow-color), inset 0 0 5px var(--glow-color);
    border-radius: 4px;
    padding: 8px;
    position: relative;
    transition: all 0.3s ease;
}

.square:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px var(--glow-color), inset 0 0 8px var(--glow-color);
}

.square-content {
    text-align: center;
    color: white;
    cursor: pointer;
    width: 100%;
}

.square-content input {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--glow-color);
    color: white;
    padding: 4px;
    border-radius: 2px;
    text-align: center;
}


.delete-button {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);
    color: var(--glow-color);
    cursor: pointer;
    font-size: 16px;
    opacity: 0;
    animation: fadeIn 0.2s ease forwards;
    transition: all 0.2s ease;
  }
  
  .delete-button:hover {
    background: var(--glow-color);
    color: black;
    transform: scale(1.1);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(25, 22, 34, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease;
  }
  
  .modal-content {
    background: #2d1b36;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    border: 2px solid var(--modal-color);
    box-shadow: 0 0 20px var(--modal-color);
    color: white;
    animation: slideIn 0.3s ease;
  }
  
  .modal-content h2 {
    margin: 0 0 16px 0;
    color: var(--modal-color);
  }
  
  .modal-content p {
    margin: 0 0 24px 0;
    line-height: 1.5;
  }
  
  .modal-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .cancel-button {
    background: transparent;
    border: 1px solid #666;
    color: #fff;
  }
  
  .cancel-button:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .confirm-button {
    background: var(--modal-color);
    color: #000;
  }
  
  .confirm-button:hover {
    filter: brightness(1.2);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { 
      opacity: 0;
      transform: translateY(-20px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }
/* AddButton.css */
.add-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: var(--button-color);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .add-button span {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 0;
    margin-top: -2px;
    transition: color 0.3s ease;
  }
  
  .add-button:hover {
    background: #000000;
  }
  
  .add-button:hover span {
    color: var(--button-color);
  }